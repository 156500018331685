import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
import { Sandbox } from '../../components/Sandbox';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Design'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><inlineCode parentName="h1">{`@arwes/design`}</inlineCode></h1>
    <p>{`Theming and styling tools with focus in `}<a parentName="p" {...{
        "href": "https://emotion.sh"
      }}>{`Emotion`}</a>{` in `}<a parentName="p" {...{
        "href": "https://reactjs.org"
      }}>{`React`}</a>{`.`}</p>
    <p>{`The design tools are heavily inspired by `}<a parentName="p" {...{
        "href": "https://material-ui.com"
      }}>{`Material UI`}</a>{`.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`All the tools are bundled and can be installed with the following NPM package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @arwes/design
`}</code></pre>
    <p>{`The design management tooling requires `}<a parentName="p" {...{
        "href": "https://polished.js.org"
      }}><inlineCode parentName="a">{`polished`}</inlineCode></a>{`
v4 as a peer-dependency.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install polished@4
`}</code></pre>
    <h2>{`Theme`}</h2>
    <p>{`Any application can have a design definition managed by a theme. A theme is a set
of visual configurations and utilities. Application components can make use of
it to create their styles and animations.`}</p>
    <p>{`A theme with default settings can be created like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme } from '@arwes/design';

const theme = createTheme();
`}</code></pre>
    <p>{`The following features can be configured:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/develop/design/breakpoints"
        }}>{`Breakpoints`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/develop/design/palette"
        }}>{`Palette`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/develop/design/multipliers"
        }}>{`Multipliers`}</a>{`.`}</li>
    </ul>
    <p>{`But any extra settings can be setup as needed.`}</p>
    <p>{`Then the theme can be used in any CSS-in-JS library theme provider or referenced
directly by components to generate dynamic styles and animations.`}</p>
    <h3>{`Theme Extension`}</h3>
    <p>{`A new theme can be created by extending a previously created one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const theme1 = createTheme({ ... });
const theme2 = createTheme({ ... }, theme1);
`}</code></pre>
    <p>{`The second theme will extend the first theme.`}</p>
    <hr></hr>
    <p>{`You can see and play with more examples in the `}<a parentName="p" {...{
        "href": "https://playground.arwes.dev/design/createTheme/basic"
      }}>{`playground`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      